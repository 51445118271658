<template>
  <div style="height: 100%">
    <a-layout-content style="height: 100%; width: 100%;background-color:white;padding:20px;">
      <h3 class="sub">订阅内容</h3>
      <!-- <a-form-model-item label="话题类型" style="margin-bottom:10px;"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol">
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Topic.ID">
                        {{ Topic.Name }}<a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <template v-for="item in MaterialTopicList">
                            <a-menu-item :key="item.ID">
                                <a @click="changeTopic(item)">{{ item.Name }}</a>
                            </a-menu-item>
                        </template>
                    </a-menu>
                </a-dropdown>
            </a-form-model-item>
            <a-form-model-item label="行业类型" style="margin-bottom:10px;"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol">
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Business.ID">
                        {{ Business.Name }}<a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <template v-for="item in MaterialBusinessList">
                            <a-menu-item :key="item.ID">
                                <a @click="changeBusiness(item)">{{ item.Name }}</a>
                            </a-menu-item>
                        </template>
                    </a-menu>
                </a-dropdown>
      </a-form-model-item>-->
      <a-row :gutter="[16, 16]">
        <a-col :span="4" v-for="info in tableData" :key="info.ID">
          <a-card hoverable :bordered="true" style="height: 320px">
            <img
              slot="cover"
              :src="Getsrc(info)"
              class="thumb-img1"
              @click="Store_PackageDetail(info.ID)"
            />
            <a-card-meta :title="info.Name">
              <template slot="description">
                <div @click="Store_PackageDetail(info.ID)">
                  <small style=" float:right">已订阅{{ info.SubscriptionCount }}</small>
                  <p class="mintro" v-html="info.Summary"></p>
                  <p class="mintro2">{{ GetPackageRule(info) }}</p>
                </div>
                <p class="option">
                  <a-button
                    :type=" info.Subscriptionbool ? 'danger' : ''"
                    @click="EditSubscription(info)"
                  >
                    {{
                    info.Subscriptionbool ? "取消订阅" : "订阅"
                    }}
                  </a-button>
                </p>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-pagination
            v-model="current"
            :total="total"
            :defaultPageSize="defaultPageSize"
            :showTotal="showTotal"
            @change="pagerChange"
          />
        </a-col>
      </a-row>
      <div style="display:none">
        <a-form layout="horizontal" style="padding: 10px 0px 0; height: auto">
          <a-tabs default-active-key="0">
            <a-tab-pane key="0" tab="模板列表" />
          </a-tabs>
        </a-form>
        <a-form-item
          label
          style="margin-bottom: 10px"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-form layout="inline">
            <a-form-item label="类型">
              <a-radio-group
                default-value="TypeID"
                v-model="TypeID"
                @change="QueryData"
                button-style="solid"
              >
                <a-radio-button
                  :value="item.ID"
                  v-for="item in TemplateTypeList"
                  :key="item.ID"
                >{{ item.Name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </a-form-item>
        <a-form-item
          label
          style="margin-bottom: 10px"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-form layout="inline">
            <a-form-item label="分类">
              <a-radio-group
                default-value="ClassID"
                v-model="ClassID"
                @change="QueryData"
                button-style="solid"
              >
                <a-radio-button
                  :value="item.ID"
                  v-for="item in TemplateClassList"
                  :key="item.ID"
                >{{ item.Name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </a-form-item>
        <a-row :gutter="[16, 16]">
          <a-col :span="4" v-for="info in StoretableData" :key="info.MaterialID">
            <a-card :bordered="false" @click="ShowDetail(info)">
              <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
              <div class="sc-txt">{{info.Name}}</div>
            </a-card>
          </a-col>
        </a-row>
        <a-row style="margin: 10px 0">
          <a-col :span="24">
            <a-pagination
              v-model="Storecurrent"
              :total="Storetotal"
              :defaultPageSize="StoredefaultPageSize"
              :showTotal="StoreshowTotal"
              @change="StorepagerChange"
            />
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
    <div v-if="DetailVisible">
      <Detail
        :closeDetail="closeDetail"
        :MaterialType="MaterialType"
        :ID="ID"
        :PushID="PushID"
        :IDType="1"
        :disabled="false"
      ></Detail>
    </div>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";
import local from "@/Plugin//local.js";
import Detail from "@/Views/MaterialModule/M2Material/Detail";
export default {
  name: "SubscribeList",
  data() {
    return {
      TemplateClassList: [],
      TemplateTypeList: [],
      tableData: [],
      clear: false,
      StoretableData: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 3 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      Topic: { Name: "全部", ID: 0 },
      Business: { Name: "全行业", ID: 0 },
      current: 1,
      thispage: 0,
      total: 0,
      defaultPageSize: 6,
      showTotal: total => `共 ${total} 条`,
      Storecurrent: 1,
      Storetotal: 0,
      StoredefaultPageSize: 12,
      StoreshowTotal: Storetotal => `共 ${Storetotal} 条`,
      MaterialType: -1,
      PushID: "",
      ID: "0",
      DetailVisible: false,
      TypeID: -1,
      ClassID: -1
    };
  },
  props: {
    //组件属性
  },
  methods: {
    GetTemplateType: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetTemplateType",
        data: {},
        OnSuccess: function(data) {
          self.TemplateTypeList.push({ Name: "全部", ID: -1 });
          data.data.forEach(e => {
            self.TemplateTypeList.push({ Name: e.Name, ID: e.ID });
          });
          self.GetTemplateClass();
        }
      };
      http.Post(op);
    },
    GetTemplateClass: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetTemplateClass",
        data: {},
        OnSuccess: function(data) {
          self.TemplateClassList.push({ Name: "全部", ID: -1 });
          data.data.forEach(e => {
            self.TemplateClassList.push({ Name: e.Name, ID: e.ID });
          });
          self.getTableData();
        }
      };
      http.Post(op);
    },
    getTableData: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetMaterialPackageList",
        data: {
          Topic: self.Topic.ID,
          pageIndex: self.current,
          PageCount: self.defaultPageSize
        },
        OnSuccess: function(data) {
          self.tableData = data.data.Data;
          self.total = data.data.Total;
          self.defaultPageSize = data.data.RowCount;
          // self.current = data.data.PageIndex;
          self.current =
            self.thispage == 0 ? data.data.PageIndex : self.thispage;
          self.StoregetTableData();
        }
      };
      http.Post(op);
    },
    StoregetTableData: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetMaterialTemplateList",
        data: {
          ClassID: self.ClassID,
          pageIndex: self.Storecurrent,
          MaterialType: self.TypeID,
          PageCount: self.StoredefaultPageSize
        },
        OnSuccess: function(data) {
          self.StoretableData = data.data.Data;
          self.Storetotal = data.data.Total;
          self.StoredefaultPageSize = data.data.RowCount;
          self.Storecurrent = data.data.PageIndex;
        }
      };
      http.Post(op);
    },
    pagerChange: function(item) {
      var self = this;
      self.current = self.thispage = item;
      self.clear=true;
      let str =
        self.$router.history.current.fullPath.indexOf("&") != -1 ? "&" : "?";
      let page =
        self.current != undefined && self.current != ""
          ? `${str}page=${self.current}`
          : "";
      local.setlocal("page", parseInt(self.thispage));
      self.$router.push(`/MaterialModule/Store/SubscribeList${page}`);
      self.getTableData();
    },
    changeTopic: function(e) {
      var self = this;
      self.Topic = e;
      self.current = 1;
      self.getTableData();
    },
    changeBusiness: function(e) {
      var self = this;
      self.Business = e;
      self.current = 1;
      self.getTableData();
    },
    GetPackageRule: function(info) {
      var rt = "";
      this.clear=true;
      if (info.PackageRule == null) {
        rt = "未配置";
      } else {
        switch (info.PackageRule.PublishFrequency) {
          case 0:
            rt = "";
            break;
          case 1:
            rt = "每日AM" + info.PackageRule.PublishTime + "推送";
            break;
          case 2:
            var List = [];
            info.PackageRule.PackageRuleItem.forEach(e => {
              List.push(e.PeriodIndex);
            });
            var PackageRuleItem = List.join("、");
            PackageRuleItem = PackageRuleItem.replace("0", "日")
              .replace("1", "一")
              .replace("2", "二")
              .replace("3", "三")
              .replace("4", "四")
              .replace("5", "五")
              .replace("6", "六");
            rt =
              "每周" +
              PackageRuleItem +
              "AM" +
              info.PackageRule.PublishTime +
              "推送";
            break;
        }
      }
      return rt;
    },
    EditSubscription(e) {
      var self = this;
      self.clear=true;
      var con = e.Subscriptionbool ? "取消订阅" : "订阅";
      this.$confirm({
        title: "提示",
        content: "是否" + con + e.Name,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          var Status = e.Subscriptionbool ? 0 : 1;
          var op = {
            url: "/MarketingModule/Material/EditSubscription",
            data: {
              PackageID: e.ID,
              Status: Status
            },
            OnSuccess: function(data) {
              console.log(data);
              self.getTableData();
              self.$message.success(con + "成功");
            }
          };
          http.Post(op);
        },
        onCancel() {}
      });
    },
    Getsrc: function(info) {
      return info.ImgTopic.replace("upload", "upload2");
    },
    Store_PackageDetail(e) {
        this.clear=true;
      this.$router.push({
        name: "Store_PackageDetail",
        query: { id: e }
      });
    },
    StoreGetsrc: function(info) {
      return info.PreViewImg.replace("upload", "upload2");
    },
    StorepagerChange: function(item) {
      var self = this;
      self.Storecurrent = item;
      self.StoregetTableData();
    },
    QueryData() {
      var self = this;
      self.Storecurrent = 1;
      self.StoregetTableData();
    },
    ShowDetail(e) {
      var self = this;
      self.MaterialType = e.MaterialType;
      self.PushID = e.ID + "";
      self.DetailVisible = true;
    },
    closeDetail(e) {
      console.log(e);
      var self = this;
      self.DetailVisible = false;
    }
  },
  computed: {
    //计算属性
  },
  watch: {},
  components: {
    Detail
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
      console.log(local.getlocal("page"));
      this.current = this.thispage = local.getlocal("page")!=undefined ? local.getlocal("page"):1
  },
  mounted: function() {
    //生命周期，组装完成
    this.GetTemplateType();
  },
  beforeDestroy: function() {
    //生命周期，销毁前
    if (!this.clear) {
      local.clearlocal();
    }
  },
};
</script>
<style scoped>
.sub {
  padding: 10px;
  font-weight: bold;
}
.sc-txt {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #5f6771;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 12px;
  cursor: pointer;
}

.sc-txt:hover {
  color: #2281f8;
}

::v-deep .ant-card-body {
  padding: 5px;
}

.mintro {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left !important;
  margin: 0;
  font-size: 12px;
  white-space: normal;
}

.mintro2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left !important;
  margin: 0;
  font-size: 12px;
  height: 36px;
  white-space: normal;
}

.thumb-img1 {
  height: 150px;
  object-fit: cover;
  outline: none;
  background: url("../../../../src/assets/nourl.png");
  background-size: cover;
}

.thumb-img {
  cursor: pointer;
  height: 450px;
  border-radius: 5px;
  object-fit: cover;
  outline: none;
  border: none;
  background: #ebebeb url("../../../../src/assets/nourl.png") no-repeat center
    center;
  background-size: contain;
  background-repeat: no-repeat;
}

.thumb-img:hover {
  opacity: 0.85;
}

.option {
  margin: 10px 0;
}
</style>